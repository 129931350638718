/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Container, PlansItemButton } from './styles';

import { BsWhatsapp } from 'react-icons/bs';

import logoSevenLife from '../../assets/logo-seven-life.png';
import lineImage from '../../assets/line-image.png';
import doctorImage from '../../assets/doctor-seven-life.png';
import benefitAgeImage from '../../assets/benefit-age.png';
import benefitFamilyImage from '../../assets/benefit-family.png';
import benefitPregnantImage from '../../assets/benefit-pregnant.png';
import benefitTimeImage from '../../assets/benefit-time.png';
import mobileImage from '../../assets/mobile-super-app.png';
import appStoreImage from '../../assets/app-store.png';
import playStoreImage from '../../assets/play-store.png';
import serviceClubsevenImage from '../../assets/service-club-seven.png';
import serviceConsultImage from '../../assets/service-consult.png';
import serviceExamsImgImage from '../../assets/service-exams-img.png';
import serviceExamsLabImage from '../../assets/service-exams-lab.png';
import serviceFuneralImage from '../../assets/service-funeral.png';
import serviceMedicineImage from '../../assets/service-medicine.png';
import serviceOdontImage from '../../assets/service-odont.png';

function Home() {
  function scrollTo(section) {
    const element = document.getElementById(section);
    element.scrollIntoView({
      block: 'start',
      scrollPadding: '0px',
      behavior: 'smooth',
    });
  }

  function openLink(link) {
    window.open(link, '_blank');
  }

  return (
    <Container>
      <nav id="home">
        <img
          onClick={() => scrollTo('#home')}
          className="logo-image"
          src={logoSevenLife}
        />
        <button onClick={() => scrollTo('services')}>SERVIÇOS</button>
        <button onClick={() => scrollTo('benefits')}>VANTAGENS</button>
        <button onClick={() => scrollTo('consult')}>CONSULTAS</button>
        <button onClick={() => scrollTo('plans')}>PLANOS</button>
        <button onClick={() => scrollTo('app')}>APP</button>
        <button onClick={() => scrollTo('business')}>EMPRESA</button>
        {/* <button className="nav-button">ÁREA DO CLIENTE</button> */}
      </nav>

      <header>
        <div className="left-area"></div>
        <div className="right-area">
          <h1>CUIDANDO DA SAÚDE DA SUA FAMÍLIA</h1>
          <h3>
            Com nossos benefícios em serviços de saúde, você tem descontos em
            consultas particulares, procedimentos, exames e muitos mais.
          </h3>
          <button onClick={() => scrollTo('plans')}>EU QUERO</button>
        </div>
      </header>

      <section className="green-line">
        <h1>BENEFÍCIOS PARA TODA FAMÍLIA</h1>
      </section>

      <section id="services" className="services-line">
        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceConsultImage} />
          <h4>CONSULTAS MÉDICAS</h4>
          <p>Mais de 22 especialidades com consultas a partir de R$ 40,00</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceExamsLabImage} />
          <h4>EXAMES LABORATORIAIS</h4>
          <p>Mais de 3 mil exames, com até 75% de desconto.</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceExamsImgImage} />
          <h4>EXAMES DE IMAGEM</h4>
          <p>Exames de imagem e cardiológicos com até 50% de desconto</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceMedicineImage} />
          <h4>MEDICAMENTOS</h4>
          <p>Descontos e benefícios em fármacias</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceOdontImage} />
          <h4>ODONTOLOGIA</h4>
          <p>Tratamentos odontológicos, estéticos e aparelhos.</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceFuneralImage} />
          <h4>AUXÍLIO FUNERAL</h4>
          <p>Até R$3.500,00 para despesas com velório e enterro.</p>
        </div>

        <div className="service-item-box" onClick={() => scrollTo('app')}>
          <img src={serviceClubsevenImage} />
          <h4>CLUBSEVEN</h4>
          <p>
            Descontos em mais de 24 mil lojas físicas e online por todo Brasil.
          </p>
        </div>
      </section>

      <section id="benefits" className="benefits-line">
        <div className="benefits-top">
          <h1>VANTAGENS</h1>
          <img src={lineImage} />
        </div>

        <div className="benefits-area">
          <div className="benefits-box">
            <div className="benefits-item">
              <img src={benefitAgeImage} alt="" />
              <p>SEM LIMITE DE IDADE</p>
            </div>
            <div className="benefits-item">
              <img src={benefitPregnantImage} alt="" />
              <p>SEM RESTRIÇÕES PARA GRAVIDEZ E DOENÇAS PRÉ-EXISTENTES </p>
            </div>
            <div className="benefits-item">
              <img src={benefitFamilyImage} alt="" />
              <p>SEM GRAU DE PARENTESCO </p>
            </div>
            <div className="benefits-item">
              <img src={benefitTimeImage} alt="" />
              <p>SEM CARÊNCIAS PARA UTILIZAÇÃO </p>
            </div>
          </div>

          <div className="benefits-image">
            <img src={doctorImage} />
          </div>
        </div>
      </section>

      <section id="consult" className="consult-line">
        <h1>VEJA COMO É FÁCIL FAZER SUAS CONSULTAS</h1>

        <div className="consult-area">
          <div className="consult-item">
            <h1>1.</h1>
            <h2>Solicitação</h2>
            <p>
              Entre em contato com a nossa Central por telefone ou WhatsApp e
              solicite o agendamento da consulta ou exame.
            </p>
            <div className="consult-marker" />
          </div>

          <div className="consult-item">
            <h1>2.</h1>
            <h2>Agendamento</h2>
            <p>
              Respeitando o valor e horário da sua preferência, entramos em
              contato com os profissionais próximos a você.{' '}
            </p>
            <div className="consult-marker" />
          </div>

          <div className="consult-item">
            <h1>3.</h1>
            <h2>Execução</h2>
            <p>
              O cliente aprova ou altera os horários. Em seguinda, a central
              comunica e confirma o agendamento com o prestador.{' '}
            </p>
            <div className="consult-marker" />
          </div>

          <div className="consult-item">
            <h1>4.</h1>
            <h2>Avaliação</h2>
            <p>
              O pagamento é feito diretamente no local e dia do procedimento.
              Após o serviço, entramos em contato para saber como foi sua
              experiência.
            </p>
            <div className="consult-marker" />
          </div>
        </div>
      </section>

      <section id="plans" className="green-line">
        <h1>ESCOLHA UM PLANO</h1>
      </section>

      <section className="plans-line">
        <div className="plans-item">
          <h1>DUO</h1>
          <h2>
            <small>R$</small>22,90
          </h2>
          <p>Para 2 pessoas</p>
          <PlansItemButton
            onClick={() =>
              openLink(
                'https://app.vindi.com.br/customer/pages/4e23adde-9036-40a1-abd2-bc1c802ae42c/subscriptions/new'
              )
            }>
            CONTRATE
          </PlansItemButton>
        </div>

        <div className="plans-item">
          <h1>FAMÍLIA</h1>
          <h2>
            <small>R$</small>33,90
          </h2>
          <p>Para 4 pessoas</p>
          <PlansItemButton
            onClick={() =>
              openLink(
                'https://app.vindi.com.br/customer/pages/391cf21a-ca2e-4b77-ad5c-ee15dc5be91e/subscriptions/new'
              )
            }>
            CONTRATE
          </PlansItemButton>
        </div>

        <div className="plans-item">
          <h1>GRANDE FAMÍLIA</h1>
          <h2>
            <small>R$</small>44,90
          </h2>
          <p>Para 6 pessoas</p>
          <PlansItemButton
            onClick={() =>
              openLink(
                'https://app.vindi.com.br/customer/pages/919f64ee-80b2-40ad-8273-3dbdce1947bd/subscriptions/new'
              )
            }>
            CONTRATE
          </PlansItemButton>
        </div>
      </section>

      <section id="app" className="app-line">
        <div>
          <img src={lineImage} style={{ marginLeft: 160 }} />
        </div>

        <div className="app-area">
          <div className="app-left-area">
            <img src={mobileImage} className="mobile-image" />
          </div>

          <div className="app-right-area">
            <h1>BAIXE NOSSO APP</h1>
            <p>
              Além dos descontos em serviços de saúde, com o ClubSeven você
              ganha cupons de descontos nos mais variados serviços e produtos,
              em mais de 24 mil lojas físicas e online por todo Brasil.
            </p>
            <h3>O App Seven Seguros é disponível para Android e IOS.</h3>
            <div className="stores-box">
              <img
                className="icon-app"
                onClick={() =>
                  openLink(
                    'https://apps.apple.com/us/app/app-seven/id1550621458'
                  )
                }
                src={appStoreImage}
                alt=""
              />
              <img
                className="icon-app"
                onClick={() =>
                  openLink(
                    'https://play.google.com/store/apps/details?id=br.com.seven.app'
                  )
                }
                src={playStoreImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="business" className="green-line">
        <h1>CONSULTE NOSSOS PLANOS ESPECIAIS PARA EMPRESAS</h1>
      </section>

      <section className="business-line">
        <div className="business-box">
          <p>
            Você pode oferecer a qualidade da SevenLife também para os
            colaboradores do seu negócio!
          </p>
          <button
            onClick={() =>
              openLink('https://api.whatsapp.com/send?phone=5534991201037')
            }>
            SAIBA MAIS
          </button>
        </div>
      </section>

      <footer>
        <p>0800 942 3290</p>
        <p
          style={{ cursor: 'pointer' }}
          onClick={() =>
            openLink('https://api.whatsapp.com/send?phone=5534991201037')
          }>
          <BsWhatsapp style={{ marginRight: 10 }} />
          (34) 99120-1037
        </p>
      </footer>
    </Container>
  );
}

export default Home;
