import styled from 'styled-components';
import {
  primary,
  secondary,
  white,
  middleGray,
  darkGray,
} from '../../styles/colorProvider';
import bannerImage from '../../assets/header-banner.png';

export const Container = styled.div`
  @media (max-width: 1000px) {
    max-width: 100% !important;
    overflow-x: hidden;
  }

  nav {
    box-sizing: border-box;
    padding: 0px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;

    @media (max-width: 700px) {
      padding: 0px 50px;
    }

    @media (max-width: 1000px) {
      justify-content: center;
    }

    button {
      color: ${primary};
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      &:hover {
        opacity: 0.7;
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .nav-button {
      background-color: ${primary};
      color: ${white};
      border-radius: 15px;
      padding: 10px 20px;
      border: none;
    }

    .logo-image {
      height: 70px;
    }
  }

  header {
    box-sizing: border-box;
    padding: 110px 100px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ebedea;
    background-image: url(${bannerImage});
    background-size: cover;

    @media (max-width: 1200px) {
      background-image: none;
      padding: 110px 30px;
    }

    .left-area {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: 1200px) {
        display: none;
      }
    }

    .right-area {
      display: flex;
      flex-direction: column;
      width: 50%;
      box-sizing: border-box;
      padding-left: 40px;

      @media (max-width: 1200px) {
        width: 100%;
      }

      h1 {
        color: ${secondary};
        font-size: 45px;
        margin: 0px;
        @media (max-width: 1000px) {
          font-size: 35px;
        }
      }

      h3 {
        color: ${primary};
        font-size: 18px;
        margin: 0px;
        font-weight: 400;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
      }

      button {
        background-color: ${primary};
        color: ${white};
        border: none;
        border-radius: 20px;
        height: 50px;
        width: 250px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  section {
    box-sizing: border-box;
    padding: 0px 100px;

    @media (max-width: 700px) {
      padding: 0px 50px;
    }

    @media (max-width: 1000px) {
      width: 100%;
      min-width: 100%;
    }
  }

  .green-line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${white};
    background-color: ${primary};
    height: 120px;
    font-size: 22px;

    @media (max-width: 1000px) {
      font-size: 18px;
      text-align: center;
    }
  }

  .services-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 100px;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      padding: 30px 50px;
    }
  }

  .service-item-box {
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${primary};
    border-radius: 15px;
    width: 150px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all ease-in-out 0.3s;

    @media (max-width: 1000px) {
      margin: 10px 0px;
    }

    img {
      width: auto;
      height: 60px;
      margin: 10px 0px;
    }

    h4 {
      font-size: 14px;
      color: ${secondary};
      text-align: center;
      height: 30px;
    }

    p {
      font-size: 12px;
      color: ${middleGray};
      font-weight: normal;
      text-align: center;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .benefits-line {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 100px;
    @media (max-width: 1000px) {
      padding: 30px 50px;
    }
  }

  .benefits-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      color: ${secondary};
      font-size: 30px;
    }
    img {
      max-width: 110%;
      margin-left: 10px;
      margin-top: -20px;
    }
  }

  .benefits-area {
    display: flex;
    flex-direction: row;
  }

  .benefits-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;

    @media (max-width: 1000px) {
      width: 100%;
      justify-content: center;
    }
  }

  .benefits-item {
    width: 48%;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 150px;
    p {
      color: ${secondary};
      font-weight: bold;
      font-size: 13px;
    }

    img {
      height: 70px;
      width: 70px;
      margin-right: 10px;
    }
  }

  .benefits-image {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      margin-top: -60px;
      width: 330px;
      height: auto;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .consult-line {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 100px;
    padding-bottom: 100px;
    background-color: #ebedea;

    h1 {
      color: ${secondary};
      font-size: 34px;
    }
  }

  .consult-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 60px;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .consult-item {
    position: relative;
    width: 20%;
    margin: 0px 20px;
    background-color: ${white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    transition: all ease-in-out 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 1000px) {
      width: 40%;
      min-width: 220px;
      margin: 30px 30px;
    }

    h1 {
      border-radius: 100%;
      height: 70px;
      width: 70px;
      background-color: ${primary};
      color: ${white};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -20px;
    }

    h2 {
      color: ${secondary};
      font-size: 20px;
      font-weight: normal;
    }

    p {
      color: ${middleGray};
      font-size: 13px;
      text-align: center;
      margin-bottom: 50px;
    }
  }

  .consult-marker {
    position: absolute;
    height: 12px;
    width: 70%;
    background-color: ${secondary};
    bottom: 0;
  }

  .plans-line {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 100px;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .plans-item {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ebedea;
    margin: 20px;
    padding: 0px !important;

    @media (max-width: 1000px) {
      width: 60%;
      min-width: 300px;
    }

    h1 {
      height: 120px;
      background-color: ${primary};
      width: 100%;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }

    h2 {
      color: ${secondary};
      font-size: 42px;
      margin: 0px;
      margin-top: 30px;
    }

    small {
      color: ${primary};
      font-size: 28px;
      margin: 0px;
    }

    p {
      color: ${darkGray};
      font-size: 19px;
      margin: 5px 0px;
    }
  }

  .app-line {
    position: relative;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 100px;

    @media (max-width: 1000px) {
      padding: 30px 50px;
    }

    img {
      max-width: 110%;
      margin-left: 10px;
      margin-top: -20px;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .mobile-image {
      width: 40%;
      margin-top: 50px;
      margin-left: 0px;
      left: 0;
      bottom: 0;
      position: absolute;
    }
  }

  .app-area {
    display: flex;
    flex-direction: row;
  }

  .app-left-area {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .app-right-area {
    width: 50%;
    @media (max-width: 1000px) {
      width: 100%;
    }

    h1 {
      color: ${secondary};
      font-size: 40px;
      margin: 0px;
      margin-top: 60px;
      @media (max-width: 1000px) {
        margin-top: 0px;
      }
    }

    p {
      color: ${primary};
      font-size: 16px;
      text-align: justify;
      width: 80%;
      font-weight: normal;
      margin: 30px 0px;
    }

    h3 {
      font-size: 12px;
      color: ${middleGray};
      border-left: 5px solid ${secondary};
      padding-left: 10px;
      margin-bottom: 40px;
    }
  }

  .stores-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -10px;

    img {
      height: 40px;
      display: flex !important;
    }
  }

  .business-line {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 100px;
    background-color: #ebedea;
  }

  .business-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    background-color: ${white};
    box-sizing: border-box;
    padding: 20px 40px;

    @media (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
      min-width: 300px;
      padding: 20px;
    }

    p {
      width: 60%;
      color: ${primary};
      @media (max-width: 1000px) {
        width: 100%;
        text-align: center;
      }
    }

    button {
      background-color: ${secondary};
      color: ${white};
      border: none;
      box-sizing: border-box;
      margin-left: 30px;
      height: 50px;
      width: 200px;
      border-radius: 15px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .icon-app {
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  footer {
    box-sizing: border-box;
    padding: 30px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${primary};
    font-size: 30px;
    font-weight: bold;
    @media (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
      padding: 30px 50px;
    }

    p {
      margin: 0px 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 1000px) {
        margin: 20px;
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export const PlansItemButton = styled.button`
  background-color: ${(props) => (props.featured ? secondary : 'transparent')};
  border: 1px solid;
  border-color: ${(props) => (props.featured ? secondary : darkGray)};
  color: ${(props) => (props.featured ? white : darkGray)};
  margin: 30px 0px;
  height: 50px;
  width: 200px;
  font-size: 16px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: ${secondary};
    border-color: ${secondary};
    color: ${white};
  }
`;
