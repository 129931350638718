import React from 'react';
import { GlobalStyle } from './styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import './styles/fontProvider.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <div className="App">
    <ToastContainer />
    <GlobalStyle />
    <Routes />
  </div>
);

export default App;
