export const primary = '#49b24b';
export const secondary = '#bc2229';
export const success = '#2ecc71';
export const error = '#e74c3c';
export const warn = '#F1C40F';
export const white = '#FFFFFF';
export const black = '#000000';
export const darkGray = '#525252';
export const middleGray = '#868686';
export const lightGray = '#d1d2d4';
export const lighterGray = '#f0f0f0';
